import * as React from 'react';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import ShoppingExtSuccessComponent from "../../components/ShoppingExtSuccess";
import DOMPurify from 'dompurify';

export default function CommerceExtInstallPage() {
    const cookieValue = Cookies.get('ncid');
    const [ncidValue, setNcidValue] = useState(null);
    const sanitizeCookieValue = () => {
        if (typeof window !== 'undefined' && !cookieValue) return null;
        // This code only runs in the browser. Should be called from useEffect or similar API.
        return DOMPurify.sanitize(cookieValue);            
    }

    useEffect(() => {
        setNcidValue(sanitizeCookieValue());
    }, [cookieValue]);
   
    return <ShoppingExtSuccessComponent ncid={ncidValue} />
}
    